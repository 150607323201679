// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.0-rc-10268(b0324d574a)-C24/05/2024-09:29:25-B24/05/2024-09:33:30' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.0-rc-10268(b0324d574a)-C24/05/2024-09:29:25-B24/05/2024-09:33:30",
  branch: "master",
  latestTag: "6.0-rc",
  revCount: "10268",
  shortHash: "b0324d574a",
  longHash: "b0324d574ab0ecc6ac66c5ea365aa64c88c1562f",
  dateCommit: "24/05/2024-09:29:25",
  dateBuild: "24/05/2024-09:33:30",
  buildType: "Ansible",
  } ;
